




































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    Fab: () => import("@/app/modules/whiteboard/components/Fab.vue"),
    Post: () => import("@/app/modules/whiteboard/components/PostComponent.vue")
  }
})
export default class FabComponent extends Vue {
  fab = false;

  get userData() {
    return this.$store.getters["profile/data"];
  }
}
